import * as React from 'react';
import styles from './Popover.module.scss';
import {
    calculateTimeTask,
    isFutureDay,
    IPopoverPopup,
} from '~/containers/Tasks/Calendar/Calendar';
import moment, { Moment } from 'moment';
import 'moment/locale/ru';
import { prepareTimeFromMinutes, difficultyToMs, groupTasksProject } from '~/utils/utils';
import Status from '~/components/Icons/Status/Status';
import { Link } from 'react-router-dom';
import { urlsMap } from '~/utils/urls';
import classNames from 'classnames';
import { TASK_STATUS, TASK_TYPES, SERVER_DATE_FORMAT_HHMMSS, SERVER_DATE_FORMAT } from '~/const';
import { useAppSelector } from '~/store';
import { selectProjects } from '~/store/slices/project/slice';
import { selectCalendarDay } from '~/store/slices/calendar/slice';
import { selectTasksByIds } from '~/store/slices/task/slice';
import Circle from '~/components/Loader/Circle/Circle';

interface IFC {
    props?: IPopoverPopup;
    closeHandler: () => void;
}

const Popover = ({ props, closeHandler }: IFC) => {
    if (!props) {
        return null;
    }

    const projects = useAppSelector((state) => selectProjects(state));
    const day = useAppSelector((state) =>
        selectCalendarDay(state, props.date.format(SERVER_DATE_FORMAT)),
    );
    const tasks = useAppSelector((state) => selectTasksByIds(state, day.tasksIds ?? []));

    const taskPlanned = tasks.filter(
        (item) =>
            item.type !== TASK_TYPES.MEETS &&
            !item.timings?.find((timing) =>
                moment(timing.stime, SERVER_DATE_FORMAT_HHMMSS).isSame(moment(), 'day'),
            ),
    );

    const task = tasks.filter(
        (item) =>
            item.type !== TASK_TYPES.MEETS &&
            !!item.timings?.find((timing) =>
                moment(timing.stime, SERVER_DATE_FORMAT_HHMMSS).isSame(moment(), 'day'),
            ),
    );

    const tasksMeets = tasks.filter((item) => item.type === TASK_TYPES.MEETS);

    const taskTime = (timing: ITiming[] = [], date?: string, day?: Moment, difficulty?: number) => {
        let time = difficultyToMs(difficulty);
        if (isFutureDay(day)) {
            if (timing && difficulty) {
                time = calculateTimeTask(time, timing, false);
            }
        } else {
            time = calculateTimeTask(time, timing, false, day);
        }
        return time / 1000 / 60;
    };

    const RenderTasks = (tasks: ITask[]) => {
        return tasks.map((task) => {
            return (
                <div
                    className={classNames(styles.task, {
                        [styles.completed]: task.status
                            ? [
                                  TASK_STATUS.ACCEPTED,
                                  TASK_STATUS.CANCELED,
                                  TASK_STATUS.COMPLETED,
                              ].includes(task.status)
                            : false,
                    })}
                    key={task.id}
                >
                    <div className={styles.title}>
                        <Link to={urlsMap.taskList + '/' + task.id} onClick={closeHandler}>
                            {task.title}
                        </Link>
                    </div>
                    <div className={styles.difficulty}>
                        {task.type === TASK_TYPES.MEETS
                            ? task.wdate
                                ? `${moment(task.wdate, SERVER_DATE_FORMAT_HHMMSS).format(
                                      'HH:mm',
                                  )} - ${moment(task.wdate, SERVER_DATE_FORMAT_HHMMSS)
                                      .add(task.difficulty, 'minutes')
                                      .format('HH:mm')}`
                                : ''
                            : prepareTimeFromMinutes(
                                  taskTime(
                                      task.timings,
                                      task.wdate,
                                      props.date,
                                      task.has_subtasks ? 15 : task.difficulty,
                                  ),
                              )}
                    </div>
                    <Status statusId={task.status} projectId={task.pid} className={styles.status} />
                </div>
            );
        });
    };

    const RenderGroup = (tasks: ITask[]) => {
        const groupTasks = groupTasksProject(tasks);
        return Object.keys(groupTasks).map((item, key) => {
            const localProject = projects[Number(item)] ?? {};

            return (
                <div className={styles.groupContainer} key={key}>
                    <div className={styles.groupTitle}>
                        {!!localProject && (
                            <Link
                                to={urlsMap.projectList + '/' + localProject.id}
                                onClick={closeHandler}
                            >
                                {localProject.title}
                            </Link>
                        )}
                        :
                    </div>
                    <div className={styles.groupTasks}>{RenderTasks(groupTasks[item])}</div>
                </div>
            );
        });
    };

    return (
        <div
            className={styles.popover}
            style={{ top: props.top, right: props.right, maxHeight: `calc(100% - ${props.top}px)` }}
        >
            <div className={styles.date}>
                Задачи на <span>{moment(props.date).locale('ru').format('D MMMM')}</span>
                {!day.fullData && (
                    <div className={styles.loader}>
                        <Circle />
                    </div>
                )}
            </div>
            {props.isCurrentDate ? (
                <>
                    {!!tasksMeets.length && (
                        <>
                            <p className={styles.popoverTitle}>Встречи</p>
                            {RenderGroup(tasksMeets)}
                        </>
                    )}
                    {!!taskPlanned.length && (
                        <>
                            <p className={styles.popoverTitle}>Запланированные</p>
                            {RenderGroup(taskPlanned)}
                        </>
                    )}
                    {!!task.length && (
                        <>
                            <p className={styles.popoverTitle}>Выполнявшиеся</p>
                            {RenderGroup(task)}
                        </>
                    )}
                </>
            ) : (
                <>
                    {!!tasksMeets.length && (
                        <>
                            <p className={styles.popoverTitle}>Встречи</p>
                            {RenderGroup(tasksMeets)}
                        </>
                    )}
                    {(!!taskPlanned.length || !!task.length) && (
                        <>
                            <p className={styles.popoverTitle}>Задачи</p>
                            {RenderGroup([...taskPlanned, ...task])}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default Popover;
