import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getTask } from '~/store/slices/task/reducers';
import { getTaskList } from '~/store/slices/taskList/reducers';
import { getUser, updateUser } from '~/store/slices/user/reducers';
import { getUserList } from '~/store/slices/userList/reducers';

export const usersAdapter = createEntityAdapter<IUser>();

export interface IUserState {
    loadedIds: number[];
    entities: Record<number, IUser[]>;
    totalCount: 0;
}

export const usersSlice = createSlice({
    name: 'user',
    initialState: {
        ...usersAdapter.getInitialState(),
        totalCount: 0,
    },
    reducers: {
        create: usersAdapter.setMany,
        add: usersAdapter.addMany,
        update: usersAdapter.upsertMany,
        remove: usersAdapter.removeMany,
        clear: usersAdapter.removeAll,
    },
    extraReducers: (builder) => {
        builder.addCase(updateUser.fulfilled, (state, action) => {
            if (action.payload) {
                usersAdapter.upsertOne(state, { ...action.payload.user });
            }
        });
        builder.addCase(getUser.fulfilled, (state, action) => {
            if (action.payload) {
                usersAdapter.upsertOne(state, { ...action.payload.user, fullData: true });
            }
        });
        builder.addCase(getUserList.fulfilled, (state, action) => {
            if (action.payload) {
                usersAdapter.upsertMany(state, action.payload.rows);
            }
        });
        builder.addCase(getTaskList.fulfilled, (state, action) => {
            if (action.payload) {
                action.payload.rows.map((task: ITask) => {
                    task?.project?.users.map((user) => {
                        usersAdapter.upsertOne(state, { ...user });
                    });
                });
            }
        });
        builder.addCase(getTask.fulfilled, (state, action) => {
            if (action.payload) {
                action.payload.task?.project?.users.map((user: IUser) => {
                    usersAdapter.upsertOne(state, { ...user });
                });
            }
        });
    },
    selectors: {
        selectUsers: (sliceState) => sliceState.entities,
        selectUser: (sliceState, id: number) => sliceState.entities[id],
    },
});

export type usersSlice = {
    [usersSlice.name]: ReturnType<typeof usersSlice['reducer']>;
};
export const { create, update, remove, clear, add } = usersSlice.actions;
export const { selectUsers, selectUser } = usersSlice.selectors;
