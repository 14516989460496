import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiGetCalendar, apiGetCalendarDay } from '~/api/calendar';

const getCalendar = createAsyncThunk('calendar/add', async () => {
    return await apiGetCalendar();
});

const getCalendarDay = createAsyncThunk('calendar/loadDay', async (date: string) => {
    return await apiGetCalendarDay(date);
});

export { getCalendar, getCalendarDay };
