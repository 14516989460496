import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getCalendar, getCalendarDay } from '~/store/slices/calendar/reducers';

export const calendarAdapter = createEntityAdapter<ICalendarDay>();

export interface ICalendarState {
    ids: number[];
    entities: Record<number, ICalendarDay[]>;
    userNorm: number;
}

export const calendarSlice = createSlice({
    name: 'calendar',
    initialState: {
        ...calendarAdapter.getInitialState(),
        userNorm: 0,
    },
    reducers: {
        create: calendarAdapter.setMany,
        add: calendarAdapter.addMany,
        update: calendarAdapter.upsertMany,
        remove: calendarAdapter.removeMany,
        clear: calendarAdapter.removeAll,
    },
    extraReducers: (builder) => {
        builder.addCase(getCalendar.fulfilled, (state, action) => {
            if (action.payload) {
                calendarAdapter.upsertMany(state, action.payload.data.calendar);
                state.userNorm = action.payload.userNorm;
            }
        });
        builder.addCase(getCalendarDay.fulfilled, (state, action) => {
            if (action.payload) {
                const day: ICalendarDay = action.payload.day;
                calendarAdapter.upsertOne(state, {
                    id: day.id,
                    tasksIds: day.tasksIds,
                    fullData: true,
                    date: day.date,
                    tasksCount: day.tasksCount,
                });
            }
        });
    },
    selectors: {
        selectCalendarUserNorm: (sliceState) => sliceState.userNorm,
        selectCalendar: (sliceState) => sliceState.ids.map((id) => sliceState.entities[id]),
        selectCalendarDay: (sliceState, date: string) => {
            return sliceState.entities[Number(date.replaceAll('-', ''))];
        },
    },
});

export type calendarSlice = {
    [calendarSlice.name]: ReturnType<typeof calendarSlice['reducer']>;
};
export const { create, update, remove, clear, add } = calendarSlice.actions;
export const { selectCalendar, selectCalendarUserNorm, selectCalendarDay } =
    calendarSlice.selectors;
