import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
    approveTiming,
    cancelTiming,
    createComment,
    getTask,
    getTaskLogs,
    getTaskTimings,
    newTask,
    updateComment,
    updateMeetStatus,
    updateTask,
    updateTaskTiming,
    uploadFilesToComment,
} from '~/store/slices/task/reducers';
import { getTaskList } from '~/store/slices/taskList/reducers';
import { loadBacklogIds } from '~/store/slices/sprint/reducers';
import { getCalendarDay } from '~/store/slices/calendar/reducers';

export const tasksAdapter = createEntityAdapter<ITask>();

export interface ITaskState {
    loadedIds: number[];
    entities: Record<number, ITask[]>;
    totalCount: 0;
}

export const tasksSlice = createSlice({
    name: 'tasks',
    initialState: {
        ...tasksAdapter.getInitialState(),
        totalCount: 0,
        viewedCount: 0,
    },
    reducers: {
        create: tasksAdapter.setMany,
        add: tasksAdapter.addMany,
        update: tasksAdapter.upsertMany,
        remove: tasksAdapter.removeMany,
        clear: tasksAdapter.removeAll,
    },
    extraReducers: (builder) => {
        builder.addCase(getTask.fulfilled, (state, action) => {
            if (action.payload) {
                console.log('!', action.payload.task);
                tasksAdapter.upsertOne(state, { ...action.payload.task, fullData: true });
            }
        });
        builder.addCase(getTaskList.fulfilled, (state, action) => {
            if (action.payload) {
                tasksAdapter.upsertMany(state, action.payload.rows);
            }
        });
        builder.addCase(newTask.fulfilled, (state, action) => {
            if (action.payload) {
                tasksAdapter.upsertOne(state, action.payload.task);
            }
        });
        builder.addCase(updateTask.fulfilled, (state, action) => {
            if (action.payload) {
                tasksAdapter.upsertOne(state, action.payload.task);
            }
        });
        builder.addCase(updateMeetStatus.fulfilled, (state, action) => {
            const { taskId, userId, status } = action.meta.arg;
            const task = state.entities[taskId];
            const assignees = {
                ...task?.assignees,
                [userId]: {
                    ...task?.assignees?.[userId],
                    sub_status: status,
                },
            };
            tasksAdapter.upsertOne(state, {
                id: taskId,
                assignees,
            } as ITask);
        });
        builder.addCase(getTaskLogs.fulfilled, (state, action) => {
            tasksAdapter.upsertOne(state, {
                id: action.meta.arg,
                logs: action.payload.logs,
            } as ITask);
        });
        builder.addCase(getTaskTimings.fulfilled, (state, action) => {
            tasksAdapter.upsertOne(state, {
                id: action.meta.arg,
                timings: action.payload.timings,
            } as ITask);
        });
        builder.addCase(updateTaskTiming.fulfilled, (state, action) => {
            const task = state.entities[action.meta.arg.taskId];
            tasksAdapter.upsertOne(state, {
                id: action.meta.arg.taskId,
                timings: task.timings?.map((timing) => {
                    if (timing.id === action.meta.arg.timingId) {
                        return {
                            ...timing,
                            stime: action.payload.timing_change_request.stime,
                            ftime: action.payload.timing_change_request.ftime,
                        };
                    }
                    return timing;
                }),
            } as ITask);
        });
        builder.addCase(approveTiming.fulfilled, (state, action) => {
            const task = state.entities[action.meta.arg.taskId];
            tasksAdapter.upsertOne(state, {
                id: action.meta.arg.taskId,
                timings: task.timings?.map((timing) => {
                    if (timing.id === action.meta.arg.timingId) {
                        return {
                            ...timing,
                            is_edited: true,
                            stime: timing.request?.stime,
                            ftime: timing.request?.ftime,
                            request: null,
                        };
                    }

                    return timing;
                }),
            } as ITask);
        });
        builder.addCase(cancelTiming.fulfilled, (state, action) => {
            const task = state.entities[action.meta.arg.taskId];
            tasksAdapter.upsertOne(state, {
                id: action.meta.arg.taskId,
                timings: task.timings?.map((timing) => {
                    if (timing.id === action.meta.arg.timingId) {
                        return {
                            ...timing,
                            is_edited: true,
                            request: null,
                        };
                    }
                    return timing;
                }),
            } as ITask);
        });
        builder.addCase(loadBacklogIds.fulfilled, (state, action) => {
            if (action.payload) {
                tasksAdapter.upsertMany(state, action.payload.rows);
            }
        });
        builder.addCase(updateComment.fulfilled, (state, action) => {
            const task = state.entities[action.meta.arg.taskId];
            tasksAdapter.upsertOne(state, {
                id: action.meta.arg.taskId,
                comments: task.comments?.map((comment) => {
                    return comment.id === action.meta.arg.commentId
                        ? action.payload.comment
                        : comment;
                }),
            } as ITask);
        });
        builder.addCase(createComment.fulfilled, (state, action) => {
            const task = state.entities[action.meta.arg.taskId];
            tasksAdapter.upsertOne(state, {
                id: action.meta.arg.taskId,
                comments: [...(task?.comments ?? []), action.payload.comment],
            } as ITask);
        });
        builder.addCase(uploadFilesToComment.fulfilled, (state, action) => {
            const task = state.entities[action.meta.arg.taskId];
            tasksAdapter.upsertOne(state, {
                id: action.meta.arg.taskId,
                comments: task.comments?.map((comment) => {
                    return comment.id === action.meta.arg.commentId
                        ? { ...comment, files: [...comment?.files, ...action.payload.files] }
                        : comment;
                }),
            } as ITask);
        });
        builder.addCase(getCalendarDay.fulfilled, (state, action) => {
            if (action.payload) {
                tasksAdapter.upsertMany(state, action.payload.day.tasks);
            }
        });
    },
    selectors: {
        selectTasks: (sliceState) => sliceState.entities,
        selectTasksByIds: (sliceState, ids: number[]) =>
            ids.map((id) => sliceState.entities[id]).filter((task) => task),
        selectTask: (sliceState, id: number) => sliceState.entities[id],
    },
});

export type tasksSlice = {
    [tasksSlice.name]: ReturnType<typeof tasksSlice['reducer']>;
};
export const { create, update, remove, clear, add } = tasksSlice.actions;
export const { selectTasks, selectTask, selectTasksByIds } = tasksSlice.selectors;
